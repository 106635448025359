﻿window.photoSlider = {
    activeIndex: 0,
    itemCount: 4,
    itemIndex: 0,
    itemTotal: 0,
    pager: null,
    photos: null,
    prevButton: null,
    nextButton: null,
    slider: null,
    thumbs: null,
    timeoutId: 0,

    init: function () {
        if (photoCount > 0) {
            photoSlider.thumbs = document.querySelectorAll('.photo-collection .slider-panel .thumb');
            photoSlider.photos = document.querySelectorAll('.photo-collection .item-photo');
            photoSlider.itemTotal = photoSlider.thumbs.length;
            photoSlider.prevButton = document.querySelector('.photo-collection .slider .prev');
            photoSlider.nextButton = document.querySelector('.photo-collection .slider .next');
            photoSlider.slider = document.querySelector('.photo-collection .slider');
            photoSlider.pager = document.querySelector('.photo-collection .slider-pagination');

            var firstPhoto = document.querySelector('.photo-collection .slider-panel .item-photo');
            firstPhoto.classList.add('active');

            // Only Show thumbs if more than 1 photo
            if (photoSlider.itemTotal > 1) {
                // thumbnails
                for (var i = 0; i < photoSlider.thumbs.length; i++) {
                    (function () {
                        var thumb = photoSlider.thumbs[i];
                        var index = i;
                        thumb.addEventListener('click', function () {
                            photoSlider.activeIndex = index;
                            photoSlider.setActive();
                        });
                    }());
                }
            }

            // Only show pagination if more photo's than shown at once
            if (photoSlider.itemTotal > photoSlider.itemCount) {
                // pagination
                for (var p = 0; p < photoCount; p += 4) {
                    (function () {
                        var pager = document.createElement('div');
                        pager.dataset.index = p;

                        if (p === 0) {
                            pager.className = 'active';
                        }

                        pager.addEventListener('click', function () {
                            photoSlider.goto(parseInt(pager.dataset.index), true);
                        });

                        photoSlider.pager.appendChild(pager);
                    }());
                }
            } else {
                photoSlider.pager.style.display = 'none';
            }

            photoSlider.goto(photoSlider.itemIndex, false);

            if (photoSlider.itemTotal > photoSlider.itemCount) {
                if (isTouchDevice()) {
                    var hammerEl = new Hammer(photoSlider.slider);
                    hammerEl.on('swipeleft', photoSlider.next);
                    hammerEl.on('swiperight', photoSlider.prev);
                }

                if (!Site.isMobile()) {
                    photoSlider.slider.addEventListener('mouseenter', function () {
                        photoSlider.showButtons();
                    });
                    photoSlider.slider.addEventListener('mouseleave', function () {
                        photoSlider.hideButtons();
                    });
                    photoSlider.prevButton.addEventListener('click', function () {
                        photoSlider.prev();
                    });
                    photoSlider.nextButton.addEventListener('click', function () {
                        photoSlider.next();
                    });                    
                }
            }

            if (!Site.isMobile()) {
                document.querySelector('.photo-collection .active-photo').addEventListener('click', function () {
                    var url = document.querySelector('.photo-collection .active-photo .photo').style.backgroundImage;
                    var caption = document.querySelector('.photo-collection .active-photo .photo .photo-text').innerText;
                    SiteModal.createImageModal(url, caption);
                });
            }

            if (isTouchDevice()) {
                var activePhoto = document.querySelector('.photo-collection .active-photo');
                var hammerPhotoEl = new Hammer(activePhoto);
                hammerPhotoEl.on('swipeleft', photoSlider.nextPhoto);
                hammerPhotoEl.on('swiperight', photoSlider.prevPhoto);
            }
        }
    },

    goto: function (index, animate) {
        photoSlider.itemIndex = index;
        photoSlider.activeIndex = index;
        photoSlider.photos = document.querySelectorAll('.photo-collection .item-photo');

        if (photoSlider.itemTotal > photoSlider.itemCount) {
            // Change active pager
            var activePager = document.querySelector('.photo-collection .slider-pagination div.active');
            activePager.classList.remove('active');
            var pagers = document.querySelectorAll('.photo-collection .slider-pagination div');
            var pagerIndex = index / photoSlider.itemCount;
            pagers[pagerIndex].className = 'active';
        }

        photoSlider.setActive();

        // Move slider panel
        if (photoSlider.itemTotal >1) {
            var sliderPanel = document.querySelector('.photo-collection .slider-panel');

            sliderPanel.style.width = photoSlider.itemTotal / photoSlider.itemCount * 100 + '%';
            for (var i = 0; i < photoSlider.photos.length; i++) {
                var photo = photoSlider.photos[i];
                photo.style.width = 100 / photoSlider.itemTotal + '%';
            }

            var transform = 'translateX(-' + 100 / photoSlider.itemTotal * photoSlider.itemIndex + '%)';
            var transition = 'transform ' + (animate ? 500 : 0) + 'ms ease';
            sliderPanel.style.transform = transform;
            sliderPanel.style.transition = transition;
        }
    },

    hideButtons: function () {
        photoSlider.nextButton.style.display = 'none';
        photoSlider.prevButton.style.display = 'none';
    },

    next: function () {
        // Add {itemCount} to index
        var index = photoSlider.itemIndex + photoSlider.itemCount;

        if (index > photoSlider.itemTotal) index = 0;
        photoSlider.goto(index, true);
    },

    nextPhoto: function () {
        var index = photoSlider.itemIndex + 1;
        if (index > photoSlider.itemTotal - 1) index = 0;
        if (index === 0 || index % photoSlider.itemCount === 0) {
            photoSlider.goto(index, true);
        } else {
            photoSlider.itemIndex = index;
            photoSlider.activeIndex = index;
            photoSlider.setActive();
        }
    },

    prev: function () {
        var index = photoSlider.itemIndex - photoSlider.itemCount;
        if (index < 0) index = photoSlider.itemTotal - photoSlider.itemCount;
        photoSlider.goto(index, true);
    },

    prevPhoto: function () {
        var index = photoSlider.itemIndex - 1;
        var gotoRange = [];
        var gotoRangeChange = [];
        for (var i = 0; i < photoSlider.itemTotal; i += photoSlider.itemCount) {
            gotoRange.push(i);
            gotoRangeChange.push(i + photoSlider.itemCount - 1);
        }

        if (index < 0) index = photoSlider.itemTotal - 1;
        var gotoIndex = gotoRangeChange.indexOf(index);

        if (gotoIndex > -1) {
            photoSlider.goto(gotoRange[gotoIndex], true);
        }
        /*has to be done all the time otherwise the goto function messes things up*/
        photoSlider.itemIndex = index;
        photoSlider.activeIndex = index;
        photoSlider.setActive();
    },

    setActive: function () {
        document.querySelector('.photo-collection .item-photo.active').classList.remove('active');
        photoSlider.photos[photoSlider.activeIndex].classList.add('active');

        var thumb = document.querySelector('.photo-collection .item-photo.active .thumb');
        var owner = thumb.dataset.owner;
        var title = thumb.dataset.description;
        var date = thumb.dataset.date;
        var activePhoto = document.querySelector('.photo-collection .active-photo .photo');
        var activeText = document.querySelector('.photo-collection .active-photo .photo .photo-text');
        var counter = document.querySelector('.photo-collection .active-photo .photo .photo-count');

        activePhoto.style.backgroundImage = thumb.style.backgroundImage;
        activeText.innerHTML = '<b>' + owner + '</b> - ' + title + ' - ' + date;
        counter.innerText = photoSlider.activeIndex + 1 + '/' + photoSlider.itemTotal;

        if (photoSlider.itemTotal === 1) {
            photoSlider.slider.style.display = 'none';
            counter.style.display = 'none';
        }
    },

    showButtons: function () {
        photoSlider.nextButton.style.display = 'block';
        photoSlider.prevButton.style.display = 'block';
    }
};

window.photoSlider.init();